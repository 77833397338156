import React from 'react';
import styled from 'styled-components';
import { Box, Card, Image } from 'rebass';
import banner from '../../assets/banner.jpg';

const List = styled.ul`
  list-style-type: none;
  li {
    margin-bottom: 0.2rem;
    span {
      &:first-child {
        display: inline-block;
        width: 30px;
        text-align: center;
      }
    }
  }
`;

function CoursesHeader() {
  return (
    <header>
      {/* <Box
        sx={{
          px: 4,
          py: [5, 5, 4, 4],
          backgroundImage: `url(${banner})`,
          backgroundSize: 'cover',
          borderRadius: 5,
          color: 'white',
          bg: 'gray',
        }}
      >
      </Box> */}
      <Card width="100%" pt={3}>
        <Image src={banner} />
      </Card>

      <h3>Anteprima</h3>
      <Box
        sx={{
          width: '100%',
          height: 0,
          paddingBottom: `${900 / 16}%`,
          position: 'relative',
          overflow: 'hidden',
          '& > iframe': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            bottom: 0,
            left: 0,
            border: 0,
          },
        }}
      >
        <iframe
          title="Dio con Noi - Desi Maxwell"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/Ts9fZt0YwdA"
          frameBorder="0"
          allowFullScreen
        />
      </Box>

      <h3>Panoramica della serie</h3>
      <p>
        Tra le copertine del libro chiamato La Bibbia, c'è una ricchezza di
        racconti affascinanti, come l'arca di Noè, Davide che sconfigge Golia,
        la nascita di Gesù e la sua resurrezione. Ma tutte queste storie sono in
        realtà parte di una storia molto più grande - una storia che si estende
        dalla creazione del nostro cosmo alla finale ricreazione del cosmo.
      </p>

      <p>
        Al centro di questa storia c'è il Dio onnipotente, che continua a
        scendere per stare con il suo popolo. Viene nel giardino, nel deserto,
        nel Tempio, nella carne, nello Spirito Santo e, infine, dimorerà tra il
        suo popolo in una nuova creazione, in cui lui sarà il centro.
      </p>
    </header>
  );
}

export default CoursesHeader;

// eslint-disable-next-line no-lone-blocks
{
  /* 
<li>
  <span role="img" aria-label="memo">
    📝
  </span>{' '}
  Use .mdx file to save course & lesson information and notes. This is a
  test
</li>
<li>
  <span role="img" aria-label="video Camera">
    📹
  </span>{' '}
  Youtube as video host
</li>
<li>
  <span role="img" aria-label="motorcycle">
    🏍️
  </span>{' '}
  Support autoplay
</li>
<li>
  <span role="img" aria-label="chart increasing">
    💹
  </span>{' '}
  Save learning progress
</li>
<li>
  <span role="img" aria-label="heart">
    💖
  </span>{' '}
  Bookmark courses
</li> */
}
